import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { FluidContainer, ContainerSmall } from "../components/base/Container"
import { Title, Subtitle, Span } from "../components/base/Text"
import { format } from "date-fns"
import Layout from "../components/layout"

const Wrapper = styled.div`
  padding: ${props => props.theme.margins.xxl} 0;
  @media (max-width: ${p => p.theme.screen.mobile}) {
    padding-top: ${props => props.theme.gutterWidth};
    padding-bottom: ${props => props.theme.margins.lg};
  }
`

const PostContainer = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.margins.xxl};
  @media (max-width: ${p => p.theme.screen.mobile}) {
    flex-direction: column;
    margin-bottom: ${props => props.theme.margins.xl};
  }
`

const ImageContainer = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  width: 240px;
  margin-right: ${props => props.theme.margins.lg};
  @media (max-width: ${p => p.theme.screen.mobile}) {
    width: 100%;
    margin-bottom: ${props => props.theme.margins.md};
  }
`

const PostDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Blog = ({ data, location }) => {
  const posts = data.posts.edges.map(({ node }) => node)

  return (
    <Layout pathname={location.pathname} light>
      <FluidContainer>
        <ContainerSmall>
          <Wrapper>
            {posts.map(post => {
              const {
                title,
                slug,
                excerpt,
                createdAt,
                backgroundImage: { fluid: image },
              } = post
              return (
                <Link to={`/${slug}/`} key={slug}>
                  <PostContainer>
                    <ImageContainer>
                      <Img fluid={image} />
                    </ImageContainer>

                    <PostDescriptionContainer>
                      <Title
                        size="md"
                        color="grey900"
                        margin="sm"
                        black
                        clickable
                      >
                        {title}
                      </Title>
                      <Subtitle size="xs" color="grey500" margin="lg">
                        {format(createdAt, "D MMM, YYYY")}
                      </Subtitle>

                      <Span size="sm" color="grey700">
                        {excerpt}
                      </Span>
                    </PostDescriptionContainer>
                  </PostContainer>
                </Link>
              )
            })}
          </Wrapper>
        </ContainerSmall>
      </FluidContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    posts: allContentfulPost(filter: { isBlogPost: { eq: true } }) {
      edges {
        node {
          title
          slug
          excerpt
          createdAt
          backgroundImage {
            fluid(maxWidth: 400) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`

export default Blog
